import React, { Fragment } from 'react';

export default ({question, answer, index}) => {
  return(
    <details className="mt-10 first:mt-2">
      <summary className="font-bold uppercase mb-4 text-xl border-b-2 border-gray-200 py-4">
        {index}.- {question}
      </summary>
      
      <p className="text-2xl text-gray-800">{answer}</p>
    </details>
    
  )
}