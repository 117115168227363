import React, { useState, useRef } from 'react';
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby';
import Container from '../components/Container';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import Faq from '../components/faq';

export default ({ data }) => {
  
  return (
    <Layout>

      <PageHeader>
        <h2 className="text-6xl">Preguntas frecuentes</h2>
        <p className="mt-2 text-sm">
          <Link to="/" className="underline">Inicio</Link>
          <i className="fas fa-chevron-right mx-2"></i>
          <Link to="/preguntas-frecuentes" className="underline">Preguntas Frecuentes</Link>
        </p>
      </PageHeader>

      <Container>
        {
          data.allFaqsJson.nodes.map((q,index) => (
            <Faq question={q.question} answer={q.answer} index={index+1}  key={index}/>
          ))
        }
      </Container>
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  {
    allFaqsJson {
      nodes {
        question
        answer
      }
    }
  }

`;